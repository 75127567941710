<template>
  <a-modal v-model="visible" :maskClosable="true" :width="1000">
    <mcma-card title="PREREQUIS A L'IMPORT EN MASSE" class="prerequis_import">
      <font size="3">
        <h6>Comment Importer mes données?</h6>
        * exporter les données du tableau, Bouton "Exporter les sites"<br />
        * lire l'onglet "lisez_moi" afin de modifier correctement les lignes des sites<br />
        * Importer le fichier qui a été exporté<br /><br />
      </font>
    </mcma-card>
    <template slot="footer">
        <mcma-spin-box :spinning="loading" :tip="'Chargement en cours'">
              <mcma-card>
                <a-row type="flex">
                  <a-col :span="12">
                    <input class="file_import" type="file" ref="fileInput" @change="processFile" />
                  </a-col>
                  <a-col :span="12">
                    <mcma-button
                      name="Modifier mes sites déjà présents"
                      color="blue"
                      leftIcon="upload"
                      :isFaIcon="true"
                      @click="importUpdateSitesSelected"
                    />
                  </a-col>
                </a-row>
              </mcma-card>

              <mcma-card>
                <a-row type="flex">
                  <a-col :span="12">
                    <input class="file_import" type="file" ref="fileInput" @change="processFile" />
                  </a-col>
                  <a-col :span="12">
                    <mcma-button
                      name="Ajouter des nouveaux sites"
                      color="blue"
                      leftIcon="upload"
                      :isFaIcon="true"
                      @click="importAddSitesSelected"
                    />
                  </a-col>
                </a-row>
          </mcma-card>
    </mcma-spin-box>
    </template>

  </a-modal>
</template>
<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import Materialize from "materialize-css"
import Axios from "@/util/Axios"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapState } from "vuex"
import _ from "lodash"
export default {
  name: "ImportSiteMasseModal",
  props: {
    visible: Boolean,
  },
  components: {
    McmaButton,
    McmaCard,
    McmaSpinBox
  },
  data () {
    return {
      loading: false,
      file: null
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
  },
  methods: {
    close() {
      this.$emit("close")
    },
    checkFileFormat() {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = this.file
      let format = true
      if (file.size > sizeMax) {
        Materialize.toast(
          "Le fichier dépasse la taille autorisé qui est de " + process.env.VUE_APP_UPLOAD_SIZE + "Mo.",
          4000,
          "amber white-text"
        )
        format = false
      }
      return format
    },
    importUpdateSitesSelected() {
      const file = this.file // this.$refs.fileInput
      const importFile = this.$refs.fileInput

       if(file == null) {
         Materialize.toast("Il faut d'abord importer le fichier", 3000, "blue darken-2")
      }else{
        this.loading = true
        console.log("file : %O", importFile)
        let importFileName = ""

        if (this.file && this.file.name && this.file.name.length > 0) {
          importFileName = this.file.value
          setTimeout(() => {
            console.log(importFileName + " imported from %O !", file)

            if (this.checkFileFormat()) {
              const formData = new FormData()
              Materialize.toast("Chargement du fichier", 1000, "lime")
              formData.append("file", file)
              formData.append("idMembre", this.membre.id)
              formData.append("idOperation", this.operation.id)
              Axios("put", "/sites/update/import", formData, {
                "Content-Type": "multipart/form-data",
              })
                .then(() => {
                  this.loading = false;
                  this.$emit("close")
                  Materialize.toast("Import des données sites terminé", 2000, "light-green darken-2")
                  this.$emit("initialize")
                })
                .catch((error) => {
                  this.loading = false;
                  this.$emit("close")
                  const listError = []
                
                  if (error.response !== null) {
                    if(error.response.data != null) {
                      console.log("error response" + error.response.data)
                      error.response.data.map((erreurLog) => listError.push(erreurLog))
                      Materialize.toast("Import des données sites terminé, certains sites n'ont pas été mis à jour, voir rapport", 4000, "blue darken-2")
                      var link = document.createElement("a");
                      link.href = window.URL.createObjectURL(new Blob([listError]))
                      link.setAttribute("download", "rapport_erreur_import")
                      link.click()
                    }
                  }
                  this.$emit("initialize")
                  this.$emit("close")
                })
            }
          }, 1000)
      }
      }
    },
    importAddSitesSelected() {
      
      const file = this.file // this.$refs.fileInput
      const importFile = this.$refs.fileInput

      if(file == null) {
         Materialize.toast("Il faut d'abord importer le fichier", 3000, "blue darken-2")
      }else{
      this.loading = true;
      console.log("file : %O", file)
      console.log("file : %O", importFile)
      let importFileName = ""

      if (this.file && this.file.name && this.file.name.length > 0) {
        importFileName = this.file.value
        setTimeout(() => {
          console.log(importFileName + " imported from %O !", file)

          if (this.checkFileFormat()) {
            const formData = new FormData()
            Materialize.toast("Chargement du fichier", 1000, "lime")
            formData.append("file", file)
            formData.append("idMembre", this.membre.id)
            formData.append("idOperation", this.operation.id)
            Axios("post", "/sites/add/import", formData, {
              "Content-Type": "multipart/form-data",
            })
              .then((reponseState) => {
                this.loading = false;
               
                if (reponseState !== null) {
                  if (reponseState.data.length > 0) {
                    console.log("nombre de sites ajoutés:" + reponseState.data.length)
                    Materialize.toast("Import des données sites terminé", 2000, "light-green darken-2")
                  }
                    this.$emit("initialize");
                    this.$emit("close");
                }
              })
              .catch((error) => {
                this.loading = false;
                const listError = []
              
               if (error.response !== null) {
                  if(error.response.data != null) {
                    console.log("error response " + error.response.data)
                    error.response.data.map((erreurLog) => listError.push(erreurLog))

                    Materialize.toast("Import des données sites terminé, certains sites n'ont pas été mis à jour, voir rapport", 4000, "blue darken-2")

                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([listError]))
                    link.setAttribute("download", "rapport_import")
                    link.click()
                  }
                }
                this.$emit("initialize")
                this.$emit("close")

              })
          }
        }, 1000)
      }
      }
    },
    processFile(event) {
      console.group("importFile@Membre")
      this.file = event.target.files[0]
      console.log("Fichier ajouté : ", this.file.name)
      console.groupEnd()
    },
  },
}
</script>
<style lang="scss">
.prerequis_import {
  background-color: #f0f0f0;
}
</style>
