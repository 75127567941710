<template>
  <mcma-card title="Liste de vos interlocuteurs" icon="noIcon" class="mcma-card-header">
    <div>
      <span>Votre progression actuelle pour cette étape est de</span>
      <template>
        <a-progress
          :strokeColor="progression === 100 ? successColor : primaryColor"
          :percent="progression"
          :format="() => progression + '%'"
          :strokeWidth="22"
        />
      </template>
    </div>
    <template #describe>
      <div
        v-if="getCurrentFlow && getCurrentFlow.describeInFlow"
        class="html-render-content"
        v-html="getCurrentFlow.describeInFlow"
        :class="{ 'can-edit': $isAdmin() }"
        @click="setEditorModal('services', 'describeInFlow')"
      />
    </template>
    <mcma-card class="mcma-card-header-body">
      <div class="sentence">
        <p class="principal-message with-bottom" v-if="progression < 100">
          Votre état actuel pour cette étape est
          <mcma-icon
            leftImage="edit_white"
            color="white"
            :coloriseText="true"
            text-transform="capitalize"
            :name="Constants.STATUS.TO_COMPLETE.text"
          />
          Vous devez avoir au moins 4 interlocuteurs en tout :
          <br />
          - un interlocuteur dont le rôle est FACTURATION désignant le responsable de la facturation (déjà renseigné
          dans les informations générales),
          <br />
          - un interlocuteur dont le rôle est SIGNATAIRE désignant le représentant légal signataire du contrat de
          fourniture,
          <br />
          - un interlocuteur dont le rôle est TECHNIQUE désignant le responsable de technique du patrimoine (déjà
          renseigné dans les informations générales),
          <br />
          - un interlocuteur dont le rôle est ADMINSTRATEUR ESPACE CLIENT désignant la personne qui sera en charge
          d’administrer votre espace client Bill-E,
          <br />
          En outre, si votre configuration d’utilisation de l’Espace Client Bill-E le nécessite, vous êtes invité à
          créer un autre interlocuteurs dont le rôle est UTLISATEUR AVANCE DE L’ESPACE CLIENT.
        </p>
      </div>
      <div class="sentence" v-if="progression === 100 && membre.stateInterlocuteurs === 'COMPLETED'">
        <p class="principal-message inline">
          Cette étape est terminée. <br />
          Pour savoir ce qu'il vous reste à faire :
        </p>
        <mcma-button name="Rendez-vous sur la page d'accueil" class="mt24" color="success" @click="goBack()" />
      </div>

      <mcma-divider class="mt20" />

      <div class="button-group">
        <mcma-button
          v-if="!membre.approuvedInterlocuteurs"
          name="Ajouter un nouvel interlocuteur"
          class="mt24 mr12"
          @click="goTo({ name: 'interlocuteurs-detail', params: { action: 'new' } })"
        />
      </div>
    </mcma-card>
    <slot></slot>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
    <mcma-end-step-modal :visible="progression === 100 && openEndStepModal === true" name="interlocuteurs" step="services" @close="closeEndStepModal" />
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import { mapMutations, mapState } from "vuex"
import Constants from "@/util/Constants"
import McmaEndStepModal from "@/components/mcma/common/McmaEndStepModal"
export default {
  name: "InterlocuteursHeader",
  props: {
    interlocuteurs: Array,
  },
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaDivider,
    McmaEditorModal,
    McmaEndStepModal,
  },
  data() {
    return {
      primaryColor: "#1890ff",
      successColor: "#4ECB74",
      showEditor: false,
      flowName: null,
      flowProp: null,
      param: null,
      openEndStepModal: true,
    }
  },
  computed: {
    ...mapState(["membre", "membreStats", "user", "operation"]),
    Constants() {
      return Constants
    },
    getCurrentFlow() {
      return this.operation.flows.find((item) => item.name === "services")
    },
    progression() {
      if (this.membreStats) {
        return this.membreStats.statInterlocuteur.progress * 100
      }
      return 0
    },
  },
  methods: {
    ...mapMutations(["setState", "updateCurrentMembre"]),
    closeEndStepModal(){
      this.openEndStepModal = false
    },
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    goTo(route) {
      this.setState({
        stateName: "interlocuteur",
        value: {},
      })
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    goBack() {
      this.$router.push({
        name: "steps",
      })
    },
    closeCertifyModal() {
      this.showCertifyModal = false
    },
  },
}
</script>

<style lang="scss"></style>
