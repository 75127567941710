<template>
  <!--  icon : sites-->
  <mcma-card title="Liste de vos sites" icon="noIcon" class="mcma-card-sites-header" :inline="true">
    <template #describe>
      <span>Votre progression actuelle pour cette étape est de</span>
    </template>
    <template #headerRightSide>
      <a-progress
        :strokeColor="progression === 100 ? successColor : primaryColor"
        :percent="progression"
        :format="() => progression + '%'"
        :strokeWidth="22"
      />
    </template>
    <div
      v-if="getCurrentFlow && getCurrentFlow.describeInFlow"
      class="html-render-content sub-describe"
      v-html="getCurrentFlow.describeInFlow"
      :class="{ 'can-edit': $isAdmin() }"
      @click="setEditorModal('sites', 'describeInFlow')"
    />

    <a-form :form="form">
      <mcma-card v-if="showEnrUniqueSite" title="Énergie renouvelable" class="mcma-card-sites-header" :inline="true">
        <a-row type="flex" :gutter="12" class="mt-0 mb-0">
          <mcma-field
            :colSpan="24"
            type="select"
            :form="form"
            :wasSubmit="wasSubmit"
            getFlow="name::sites"
            getPanel="name::Localisation"
            getField="type::enrUniqueSite"
            :defaultValue="membre.enrUniqueSite"
            @change="updateEnrUnique()"
          >
            <template #information class="flex-column">
              <div
                v-if="getCurrentFlow && getCurrentFlow.enrDescribe"
                class="html-render-content"
                v-html="getCurrentFlow.enrDescribe"
                :class="{ 'can-edit': $isAdmin() }"
                @click="setEditorModal('sites', 'enrDescribe')"
              />
              <span class="action" @click="openEnrInfosModal">Cliquer ici</span>
              pour en savoir plus.
            </template>
          </mcma-field>
        </a-row>
      </mcma-card>
    </a-form>

    <mcma-card class="mcma-card-sites-header-body">
      <div class="sentence" v-if="progression !== 100">
        <p class="principal-message inline">Pour finir cette étape, vous devez compléter tous vos sites.</p>
      </div>
      <div class="sentence" v-else>
        <p class="principal-message inline">Pour savoir ce qu'il vous reste à faire :</p>
        <mcma-button name="Rendez-vous sur la page d'accueil" class="mt24" color="success" @click="goBack()" />
      </div>

      <mcma-divider v-if="progression === 100" class="mt20" />

      <div class="button-group">
        <mcma-button
          v-if="membre.status !== 'CERTIFIED' && membre.status !== 'APPROVED'"
          name="Ajouter un nouveau site"
          class="mt24 mr12"
          @click="goTo({ name: 'sites-detail', params: { action: 'new' } })"
        />
        <mcma-button
          v-if="membre.status !== 'CERTIFIED' && membre.status !== 'APPROVED'"
          name="Modifier en masse vos sites"
          class="mt24 ml12"
          @click="goTo({ name: 'sites-detail', params: { action: 'masse' } })"
        />
        <mcma-button
          v-if="membre.status !== 'CERTIFIED' && membre.status !== 'APPROVED'"
          name="Associer un interlocuteur en masse"
          class="mt24 ml12"
          @click="openSitesInlocuteursMasse()"
        />
      </div>
    </mcma-card>

    <slot></slot>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
    <mcma-enr-infos-modal
      :visible="openEnrInfos"
      :htmlRender="getCurrentFlow && getCurrentFlow.enrModalDescribe ? getCurrentFlow.enrModalDescribe : null"
      @setEditorModal="setEditorModal('sites', 'enrModalDescribe')"
      @close="closeEnrInfos"
    />

    <mcma-sites-interlocuteurs-masse-modal
      :visible="showSitesInterlocuteursMasse"
      :sites="sites"
      @cancel="closeSitesInterlocuteurMasse"
      @save="saveSitesInterlocuteurMasse"
    />
    <mcma-end-step-modal :visible="progression === 100 && openEndStepModal === true" name="Sites" step="sites" @close="closeEndStepModal" />
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEnrInfosModal from "@/components/mcma/common/McmaEnrInfosModal"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { mapMutations, mapState, mapActions } from "vuex"
import Axios from "@/util/Axios"
import McmaSitesInterlocuteursMasseModal from "@/components/mcma/steps/sites/components/SitesHeader/McmaSitesInterlocuteursMasseModal"
import Constants from "@/util/Constants"
import McmaEndStepModal from "@/components/mcma/common/McmaEndStepModal"
export default {
  name: "SitesHeader",
  props: {
    sites: Array,
  },
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaDivider,
    McmaEditorModal,
    McmaEnrInfosModal,
    McmaField,
    McmaSitesInterlocuteursMasseModal,
    McmaEndStepModal,
  },
  data() {
    return {
      primaryColor: "#1890ff",
      successColor: "#4ECB74",
      showEditor: false,
      flowName: null,
      flowProp: null,
      isValid: false,
      form: this.$form.createForm(this),
      wasSubmit: false,
      openEnrInfos: false,
      updateMembreInc: false,
      showSitesInterlocuteursMasse: false,
      openEndStepModal: true, 
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation", "membreStat"]),
    ...mapActions(["updateMembre"]),
    progression() {
      if (this.sites && this.membre) {
        let progress = 0
        const siteToComplete = this.sites.filter((site) => site.state === Constants.STATUS.TO_COMPLETE.value)
        const siteCompleted = this.sites.filter(
          (site) => site.state === Constants.STATUS.COMPLETED.value || site.state === Constants.STATUS.CERTIFIED.value
        )
        progress += 0.5 * (siteToComplete.length || 0)
        progress += 1 * (siteCompleted.length || 0)
        return progress > 0 ? Math.trunc((progress / this.sites.length) * 100) : 0
      }
      return 0
    },
    getCurrentFlow() {
      return this.operation.flows.find((item) => item.name === "sites")
    },
    showEnrUniqueSite() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Localisation",
        "type::enrUniqueSite"
      )
      return enrUniqueSite ? enrUniqueSite.displayed : false
    },
  },
  methods: {
    ...mapMutations(["setState"]),
    ...mapActions(["refreshStore"]),
    closeEndStepModal(){
      this.openEndStepModal = false
    },
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    goBack() {
      // this.setBreadcrumb("goBack");
      this.$router.push({
        name: "steps",
      })
    },
    updateEnrUnique() {
      if (!this.updateMembreInc) {
        this.updateMembreInc = true
        let value = this.form.getFieldValue("enrUniqueSite")
        console.log("> enrUniqueSite new Value : %O", value)
        console.log("> enrUniqueSite new Value via form: %O", this.form.getFieldValue("enrUniqueSite"))
        console.log("> membre : %O", this.membre)
        Axios("patch", "api/membres/" + this.membre.id, {
          enrUniqueSite: this.form.getFieldValue("enrUniqueSite"),
        })
          .then((response) => {
            this.$notification.success({
              message: "Paramètres mis à jour !",
              description: `Le taux ENR unique ${response.data.enrUniqueSite}% a été mis à jour`,
            })
            this.setState({
              stateName: "membre",
              value: response.data,
            })
            this.refreshStore()
            this.updateAllSites()
          })
          .catch(() => {
            this.$notification.error({
              message: "Paramètres mis à jour !",
              description: `Oupss... Une erreur est survenue lors de l'application du taux unique ENR`,
            })
          })

        this.updateMembreInc = false
      }
    },
    updateAllSites: function () {
      if (this.sites) {
        console.log("> Nombre de site modifié : %O", this.sites.length)
        // TODO : Passer par les promise ça sera plus élégant
        this.sites.forEach((site) => {
          Axios("patch", "api/sites/" + site.id, {
            enr: this.form.getFieldValue("enrUniqueSite"),
            bioGaz: 1,
            bioGazValue: this.form.getFieldValue("enrUniqueSite"),
          }).catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur d'application du taux unique sur un site",
              description: "Une erreur est survenue lors de l'application du taux unique sur le site " + site.nom,
            })
          })
        })
      }
    },
    openEnrInfosModal() {
      this.openEnrInfos = true
    },
    closeEnrInfos() {
      this.openEnrInfos = false
    },
    openSitesInlocuteursMasse() {
      this.showSitesInterlocuteursMasse = true
    },
    closeSitesInterlocuteurMasse() {
      this.showSitesInterlocuteursMasse = false
    },
    saveSitesInterlocuteurMasse(site) {
      this.showSitesInterlocuteursMasse = false
      this.$emit("updateSite", site)
    },
  },
  created() {
    console.group("created@SitesHeader")
    console.log(this)
    console.groupEnd()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-sites-header {
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    .mcma-card-sites-header-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        align-items: center;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
          .mcma-icon {
            align-self: center;
            background: var(--success-color, $success-color);
            border-radius: 4px;
            padding: 8px 15px 7px 15px;
            margin: 20px 0;
            font-weight: 900;
            img {
              height: 22px;
            }
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $current-color);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $current-color);
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .button-group {
    display: flex;
    flex-flow: row nowrap;
  }
}
</style>
