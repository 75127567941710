var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page !== null && _vm.total)?_c('center',[_c('ul',{staticClass:"pagination"},[_c('li',{class:{
        disabled: _vm.rangeIndex === 0,
        'waves-effect': _vm.rangeIndex !== 0,
      },on:{"click":function($event){return _vm.ranging(_vm.rangeIndex - 1)}}},[_c('a',[_c('font-awesome-icon',{staticClass:"red-text",attrs:{"icon":"angle-double-left"}})],1)]),_c('li',{class:{ disabled: _vm.page === 0, 'waves-effect': _vm.page !== 0 },on:{"click":function($event){return _vm.paging(_vm.page - 1)}}},[_c('a',[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_left")])])]),_vm._l((_vm.range),function(n){return _c('li',{key:n,staticClass:"waves-effect",class:{ active: n === _vm.page + 1 },on:{"click":function($event){return _vm.paging(n - 1)}}},[_c('a',[_vm._v(" "+_vm._s(n)+" ")])])}),_c('li',{class:{
        disabled: _vm.page === _vm.total - 1,
        'waves-effect': _vm.page !== _vm.total - 1,
      },on:{"click":function($event){return _vm.paging(_vm.page + 1)}}},[_c('a',[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_right")])])]),_c('li',{class:{
        disabled: _vm.rangeIndex === _vm.maxRangeIndex,
        'waves-effect': _vm.rangeIndex !== _vm.maxRangeIndex,
      },on:{"click":function($event){return _vm.ranging(_vm.rangeIndex + 1)}}},[_c('a',[_c('font-awesome-icon',{staticClass:"red-text",attrs:{"icon":"angle-double-right"}})],1)])],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }