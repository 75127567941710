var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"container"},[_c('section',{staticClass:"row"},[_c('article',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('span',{staticClass:"card-title"},[_vm._v(" Liste de vos périmètres de facturation ")]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"determinate",style:({ width: _vm.progression * 100 + 1 + '%' })})]),_c('blockquote',[(_vm.progression > 0)?_c('div',[_vm._v(" Votre progression actuelle pour cette étape est de "+_vm._s(_vm.$n(_vm.progression, "percent"))+". ")]):_vm._e(),_c('br'),_c('span',{staticClass:"chip white-text deep-orange"},[_c('i',{staticClass:"material-icons"},[_vm._v("link")]),_vm._v(" Sites non reliés "+_vm._s(_vm.membre.siteNotGrouped)+" ")]),_c('span',{staticClass:"chip white-text lime"},[_c('i',{staticClass:"material-icons"},[_vm._v("link")]),_vm._v(" Sites reliés "+_vm._s(_vm.membre.statSite.size - _vm.membre.siteNotGrouped)+" ")]),_c('br'),(_vm.progression !== 1 || !_vm.membre.siteNotGrouped)?_c('span',[_vm._v(" Pour finir cette étape, vous devez : "),_c('br'),_vm._v(" 1- compléter les informations de chacun de vos périmètres de facturation "),_c('br'),_vm._v(" 2- vérifier d'avoir bien associé l'ensemble de vos sites sur vos périmètres de facturation "),_c('br')]):_vm._e(),_c('br'),_c('br')]),(_vm.progression === 1)?_c('span',{staticClass:"progression-finish"},[_vm._v(" Cette étape est teCette étape est terminée. "),_c('br'),_vm._v(" Pour savoir ce qu'il vous reste à faire : "),_c('router-link',{staticClass:"waves-effect waves-light btn blue darken-2",attrs:{"to":{ name: 'home' }}},[_vm._v(" rendez-vous sur la page d'accueil ")])],1):_vm._e()]),(_vm.membre.status !== 'EXPORTED' && _vm.membre.status !== 'APPROVED')?_c('center',{staticClass:"card-action"},[_c('router-link',{staticClass:"waves-effect waves-light btn blue darken-2",attrs:{"to":{
              name: 'groupements-detail',
              params: { groupementId: null, action: 'new' },
            }}},[_vm._v(" Ajouter un périmètre de facturation ")])],1):_vm._e()],1)])]),_c('section',{staticClass:"row"},[_c('article',{staticClass:"col s12",attrs:{"id":"validGroupGrid"}},[_c('place-holder',{attrs:{"notReady":!_vm.groupements}},[(_vm.groupements)?_c('grid',{attrs:{"rows":_vm.groupements,"columns":_vm.columns,"search":true,"columnHeader":_vm.columnHeader,"columnsNoSearch":['Actions'],"columnsListFilter":_vm.columnsListFilter},scopedSlots:_vm._u([{key:"state",fn:function(props){return [(props.row.state === 'TO_COMPLETE')?_c('router-link',{staticClass:"chip white-text deep-orange progression validGroupAction tutorialNoBtn",attrs:{"to":{ name: 'groupement', params: { id: props.row.id } },"tag":"span"}},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")]),_vm._v(" À completer ")]):(props.row.state === 'TO_CERTIFY' && _vm.membre.siteNotGrouped > 0)?_c('router-link',{staticClass:"chip white-text deep-orange progression validGroupAction tutorialNoBtn",attrs:{"to":{ name: 'groupements-link', params: { id: props.row.id } },"tag":"span"}},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")]),_vm._v(" Vérifier l'association de vos sites ")]):(props.row.state === 'TO_CERTIFY' && _vm.membre.siteNotGrouped === 0)?_c('router-link',{staticClass:"chip white-text amber progression validGroupAction tutorialNoBtn",attrs:{"to":{ name: 'groupement', params: { id: props.row.id } },"tag":"span"}},[_c('i',{staticClass:"material-icons"},[_vm._v("send")]),_vm._v(" À certifier ")]):_c('router-link',{staticClass:"chip white-text lime progression",attrs:{"to":{ name: 'groupement', params: { id: props.row.id } },"tag":"span"}},[_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_vm._v(" Certifié ")])]}},{key:"Actions",fn:function(props){return [_c('center',[_c('router-link',{staticClass:"chip white-text grey darken-2 progression validGroupAction tutorialNoBtn",attrs:{"to":{
                  name: 'groupements-link',
                  params: { id: props.row.id },
                },"tag":"span"}},[_vm._v(" Associer vos sites à une facture ")]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:('Modifier le périmètre de facuration'),expression:"'Modifier le périmètre de facuration'",modifiers:{"auto":true}}],staticClass:"tutorialNoBtn",attrs:{"to":{ name: 'groupement', params: { id: props.row.id } },"id":"editGroupment"}},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")])]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:('Associer des sites en masse'),expression:"'Associer des sites en masse'",modifiers:{"auto":true}}],staticClass:"tutorialNoBtn",attrs:{"to":{
                  name: 'groupements-link',
                  params: { id: props.row.id },
                },"id":"validGroupSites"}},[_c('i',{staticClass:"material-icons cyan-text"},[_vm._v("link")])]),(_vm.membre.status !== 'EXPORTED' && _vm.membre.status !== 'APPROVED')?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:('Dupliquer le périmètre'),expression:"'Dupliquer le périmètre'",modifiers:{"auto":true}}],attrs:{"to":{
                  name: 'groupement',
                  params: { id: props.row.id },
                  query: { action: 'duplique' },
                }}},[_c('i',{staticClass:"material-icons blue-grey-text"},[_vm._v("control_point_duplicate")])]):_vm._e(),(props.row.state !== 'CERTIFIED')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:('Supprimer le périmètre'),expression:"'Supprimer le périmètre'",modifiers:{"auto":true}}],attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.remove(props.row)}}},[_c('i',{staticClass:"material-icons deep-orange-text"},[_vm._v("remove")])]):_vm._e()],1)]}}],null,false,2532772971)}):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }